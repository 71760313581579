import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import { useConsentConfig } from 'components/ConsentConfigProvider'

const STATIC_Z_INDEX = 999999


export default function ThemeProvider({ children }) {
  const { theme } = useConsentConfig()

  // I'm experimenting with the use of CSS variables. If this winds
  // up working well for us, perhaps we can publish these variable names
  // to facilitate severe customization for our technical customers.
  const style = useMemo(() => ({
    '--termly-theme-background-color': theme.background,
    '--termly-theme-button-background-color': theme.btn_background,
    '--termly-theme-button-text-color': theme.btn_text_color,
    '--termly-theme-color': theme.color,
    '--termly-theme-font-family': theme.font_family,
    '--termly-theme-font-size': theme.font_size,
    '--termly-z-index': `var(--termly-override-z-index, ${ STATIC_Z_INDEX })`,
  }), [theme])

  // I don't *think* we'll need to set up an actual context provider
  // with its own custom hook because the values that we need aren't
  // typically needed in the JS code. We can always change that in
  // the future, of course.
  return (
    <div style={ style }>
      { children }
    </div>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}
