import {
  saveACString,
  saveTCString,
  saveTCFConsentableContentVersion,
} from 'utils/tcf-gvl'

import applyConsentStringsToDOM from './applyConsentStringsToDOM'


export default function makeSaveConsent({ ccVersion, cmpApi }) {
  return ({ acString, tcString }) => {
    cmpApi.update(tcString)

    saveACString(acString)
    saveTCFConsentableContentVersion(ccVersion)
    saveTCString(tcString)

    applyConsentStringsToDOM({
      acString,
      tcString,
    })
  }
}
