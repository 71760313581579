import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Button } from '@termly/react-components'

import Locale from './locale'
import Styles from './styles.module.sass'


export default function BlockedContent({ site, handleAcceptClick, handleManagePrefsClick }) {
  const isShowingManagePrefsButton = Boolean(handleManagePrefsClick)

  const className = `${ Styles.root } t-blockedContent`
  const acceptButtonClassName = `${ Styles.acceptButton } t-enableButton`

  return (
    <div className={ className }>
      <div className={ Styles.shadowBox }>
        <div className={ Styles.title }>
          <FormattedMessage
            { ...Locale.title }
          />
        </div>
        <div className={ Styles.content }>
          {
            renderContent({
              site,
              isShowingManagePrefsButton,
            })
          }
        </div>
        <div className={ Styles.buttons }>
          {
            renderManagePreferencesButton({
              handleManagePrefsClick,
            })
          }
          <Button
            preventDefault
            className={ acceptButtonClassName }
            color="primary"
            handleClick={ handleAcceptClick }
            type="solid"
          >
            <FormattedMessage
              { ...Locale.accept }
            />
          </Button>
        </div>
      </div>
    </div>
  )
}

BlockedContent.propTypes = {
  handleAcceptClick: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,

  /** Hides the preferences button when undefined */
  handleManagePrefsClick: PropTypes.func,
}

function renderContent({ site, isShowingManagePrefsButton }) {
  const values = {
    site,
    b: (chunks) => (
      <b>{ chunks }</b>
    ),
  }

  const content = [
    <FormattedMessage
      key="a"
      { ...Locale.thirdPartyNotice }
      values={ values }
    />,
  ]

  if ( isShowingManagePrefsButton ) {
    content.push(
      <FormattedMessage
        key="c"
        { ...Locale.toAllowContent }
      />
    )
  }

  return content
}

function renderManagePreferencesButton({ handleManagePrefsClick }) {
  if ( !handleManagePrefsClick ) {
    return null
  }

  const className = `${ Styles.prefsButton } t-preferencesButton`

  return (
    <Button
      preventDefault
      className={ className }
      color="primary"
      handleClick={ handleManagePrefsClick }
      type="outline"
    >
      <FormattedMessage
        { ...Locale.managePrefs }
      />
    </Button>
  )
}
