import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import Termly from 'termly-namespace'

import GACMContext from './GACMContext'

import useGACMVendorsQuery from './hooks/useGACMVendorQuery'

import ACModel from './lib/ac-model'
import ACString from './lib/ac-string'


// TODO: Consolidate with GACMConsentsProvider? See the TODO in
// that component.
//
export default function GACMProvider({ children, websiteUUID }) {
  const {
    vendors,
  } = useGACMVendorsQuery({
    websiteUUID,
  }, {
    // TODO: I'm not thrilled with the use of Termly.tcf here, but I'm
    // lacking the creative "juice" right now to come up with a better
    // solution.
    enabled: Boolean(Termly.tcf),
  })

  const acModel = useMemo(() => {
    const vendorIDs = vendors?.map(({ id }) => id)

    return new ACModel(vendorIDs)
  }, [vendors])

  const value = useMemo(() => ({
    vendors,

    acceptAll() {
      acModel.setAll()
    },

    declineAll() {
      acModel.unsetAll()
    },

    decodeACString(acString) {
      return ACString.decode(acString, acModel)
    },

    getStoredACString() {
      // TODO: Stop getting this from Termly.tcf...seems unnecessary, and it doesn't
      // have anything (directly) to do with the acModel state. It also blurs the line
      // I'm trying to draw between TCF and GACM consents.
      return Termly.tcf?.getACString()
    },

    makeACString() {
      return ACString.encode(acModel)
    },
  }), [acModel, vendors])

  return (
    <GACMContext.Provider
      value={ value }
    >
      { children }
    </GACMContext.Provider>
  )
}

GACMProvider.propTypes = {
  websiteUUID: PropTypes.string.isRequired,

  children: PropTypes.node,
}
