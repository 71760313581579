import needsBlocking from './needsBlocking'
import setBlockerAttributes from './setBlockerAttributes'


/**
 * Creates an attribute setter override for an HTMLElement object.
 *
 * @param {String} attr - the name of the attribute to set
 *
 * @returns {Function}
 */
export default function makeAttributeSetter(attr) {
  if ( !attr ) {
    throw new Error('No attribute name supplied to makeAttributeSetter()')
  }

  /**
   * If the attached element has a `src` attribute value that is not
   * to be blocked, or if the element has already been auto-blocked,
   * nothing unusual happens--the supplied `type` value is set on the
   * element.
   *
   * However, if the `src` value is to be blocked, the `type`
   * attribute will be set to the default override value, and other
   * attributes will be added to the element to flag it as having been
   * blocked.
   *
   * @param {String} type - the value to set on the element's `type` attribute
   *
   * @this HTMLElement
   */
  return function(value) {
    // If we're setting the src attribute, we want to test the incoming
    // value, not the one currently assigned to the element.
    const src = ( attr === 'src' ) ? value : this.getAttribute('src')
    if ( needsBlocking(this, src) ) {
      setBlockerAttributes(this, src)
      return
    }

    this.setAttribute(attr, value)
  }
}
