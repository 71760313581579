import {
  useCallback,
  useMemo,
} from 'react'

import EventMap from 'constants/eventMap'

import updateCookieConsent from 'utils/updateCookieConsent'
import { updateDocDict } from 'utils/cookie'

import { useGACM } from 'components/GACMProvider'
import { useTCF } from 'components/TCFProvider'
import { useConsentConfig } from 'components/ConsentConfigProvider'
import { useWebsite } from 'components/WebsiteProvider'


// TODO: There is still a fair amount of cleanup that needs to be done here. I made
// this hook by simply moving all of the code you see here from `hoc/preview.js`,
// and made as few changes as possible so that I reduce my risk of breaking things.
//
// It didn't seem like a good idea to put this into ConsentConfigProvider because
// of the dependency we have here on WebsiteProvider.
//
export default function useSaveConsent() {
  const consentConfig = useConsentConfig()

  const {
    cookiePolicy,
    documents,
  } = useWebsite()

  const { makeACString } = useGACM()

  const {
    acceptAll: acceptAllTCF,
    declineAll: declineAllTCF,
    makeTCString,
    saveConsent: saveTCFConsent,
  } = useTCF()

  const acceptPolicy = useCallback((saveCookieConsent) => {
    updateDocDict(documents)

    saveCookieConsent()
  }, [documents])

  // This is essentially a private function that serves only to
  // reduce code duplication between acceptAll() and declineAll()
  const saveConsent = useCallback((cookieConsent) => {
    const eventType = EventMap[consentConfig.display_style]

    const acString = makeACString()
    const tcString = makeTCString({
      addtlConsent: acString,
    })

    saveTCFConsent({
      acString,
      tcString,
    })

    acceptPolicy(
      updateCookieConsent({
        acString,
        consentMode: consentConfig.consent_mode,
        cookieConsent,
        cookiePolicy,
        eventType,
        tcString,
      })
    )
  }, [acceptPolicy, consentConfig, cookiePolicy, makeACString, makeTCString, saveTCFConsent])

  const acceptAll = useCallback(() => {
    acceptAllTCF()

    saveConsent({
      advertising: true,
      analytics: true,
      essential: true,
      performance: true,
      social_networking: true,
      unclassified: true,
    })
  }, [acceptAllTCF, saveConsent])

  const declineAll = useCallback((additionalArgs) => {
    declineAllTCF()

    saveConsent({
      advertising: false,
      analytics: false,
      essential: true,
      performance: false,
      social_networking: false,
      unclassified: false,
      ...additionalArgs,
    })
  }, [declineAllTCF, saveConsent])

  return useMemo(() => ({
    acceptAll,
    declineAll,
    saveConsent,
  }), [acceptAll, declineAll, saveConsent])
}
