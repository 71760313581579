import { CmpApi } from '@iabtechlabtcf/cmpapi'

import {
  CMP_ID,
  CMP_VERSION,
  CONSENTABLE_CONTENT_VERSION,
  USES_SERVICE_SPECIFIC_SCOPE,
} from './constants'

import getGVLMetadata from './getGVLMetadata'
import installTCFMutationObserver from './installTCFMutationObserver'
import makeIsDomainHandledByTCF from './makeIsDomainHandledByTCF'

import {
  clearTCFStore,
  getACString,
  getTCFConsentableContentVersion,
  getTCString,
} from './tcfStore'

const IS_SHOWING_UI_TO_USER = true


// This function should return only the data and functions that are required
// to manipulate TCF consents outside the context of a React component or hook
// (e.g., in auto-blocker). TCFProvider should be used for TCF shenanigans inside
// of those contexts.
//
export default function initTCF() {
  const gvlMetadata = getGVLMetadata()

  if ( !gvlMetadata ) {
    return
  }

  const cmpApi = new CmpApi(CMP_ID, CMP_VERSION, USES_SERVICE_SPECIFIC_SCOPE)

  // This is basically just to trigger window.__tcfapi() to let vendors
  // (and the CMP Validator) know that everything's all setup. If we omit
  // this step, the CMP validator will not work.
  cmpApi.update(getTCString())

  installTCFMutationObserver()

  const isConsentableContentVersionChanged = ( CONSENTABLE_CONTENT_VERSION !== getTCFConsentableContentVersion() )

  const isDomainHandledByTCF = makeIsDomainHandledByTCF(gvlMetadata.tcf_domains)

  return {
    ccVersion: CONSENTABLE_CONTENT_VERSION,
    cmpApi,
    disable: cmpApi.disable,
    getACString,
    getTCString,
    isConsentableContentVersionChanged,
    isDomainHandledByTCF,

    expireConsents() {
      // We show the UI to the user to prompt him for fresh consent
      cmpApi.update('', IS_SHOWING_UI_TO_USER)
      clearTCFStore()
    },
  }
}
