import CookieConsents from 'utils/cookieConsents'

import needsBlocking from '../needsBlocking'

import {
  isIframe,
  isScript,
} from '../tagUtils'

import {
  iframeBlocker,
  scriptBlocker,
} from './blockers'


/**
 * @param {MutationRecord[]} mutations
 */
export function mutationCallback(mutations) {
  if ( CookieConsents.isAllConsented() ) {
    return
  }

  mutations.forEach(({ addedNodes }) => {
    addedNodes.forEach((node) => {
      // This skips over things like text nodes.
      if ( node.nodeType !== Node.ELEMENT_NODE ) {
        return
      }

      if ( !needsBlocking(node) ) {
        return
      }

      if ( isScript(node) ) {
        scriptBlocker(node)
        return
      }

      if ( isIframe(node) ) {
        iframeBlocker(node)
        return
      }
    })
  })
}

// TODO: Don't export this object...let the caller construct the
// MutationObserver with the `mutationCallback()` exported above.
export default new MutationObserver(mutationCallback)
