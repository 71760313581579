import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'
import { TCString } from '@iabtechlabtcf/core'

import Termly from 'termly-namespace'

import noop from 'utils/noop'

import {
  makeSaveConsent,
  makeTCModel,
} from 'utils/tcf-gvl'

import { useGVL } from '../GVLProvider'
import { useGACM } from '../GACMProvider'

import TCFContext from './TCFContext'

import GVLPresenter from './lib/GVLPresenter'


// TODO: Consolidate with TCFConsentsProvider? See the TODO in
// that component.
//
export default function TCFProvider({ children, lang, tcfSettings }) {
  // TODO: I don't like this, as it (unnecessarily) couples TCFProvider to GACMProvider.
  // It seems to me that what I really need to do here is to pass in a handler or two
  // that I can invoke when appropriate (e.g., `handleAcceptAll`, `handleDeclineAll`).
  //
  // There is an XNOR relationship between GACM and TCF, and the results of the former
  // need to be available in the latter in order to augment the TCModel with the
  // `addtlConsent` and `enableAdvertiserConsentMode` to the TCModel instance [TER-13805].
  // We also need to be able to "approve all" and "reject all" for the GACM vendors when
  // those respective buttons are clicked.
  //
  const gacm = useGACM()
  const { gvl } = useGVL()

  const value = useMemo(() => {
    if ( !gvl ) {
      console.debug('[Termly] No GVL found for this website. TCFProvider is providing noop functions.')

      return {
        acceptAll: noop,
        declineAll: noop,
        getStoredTCString: noop,
        gvl: void 0,
        makeTCString: noop,
        saveConsent: noop,
        tcModel: void 0,
      }
    }

    const saveConsent = makeSaveConsent({
      ccVersion: Termly.tcf.ccVersion,
      cmpApi: Termly.tcf.cmpApi,
    })

    // TODO: Move GVLPresenter into GVLProvider? That would allow us to
    // stop passing `lang` as a prop into TCFProvider
    const gvlPresenter = new GVLPresenter({
      lang,
      vendorList: gvl,
    })

    const tcModel = makeTCModel(gvl)

    const makeTCString = ({ addtlConsent }) => {
      const model = tcModel.clone()

      // https://developers.google.com/tag-platform/security/guides/implement-TCF-strings
      model.enableAdvertiserConsentMode = Boolean(tcfSettings?.enable_advertiser_consent_mode)
      model.addtlConsent = addtlConsent

      return TCString.encode(model)
    }

    return {
      gvl: gvlPresenter,
      makeTCString,
      saveConsent,

      tcModel,

      acceptAll() {
        gacm.acceptAll()
        tcModel.setAll()
      },

      declineAll() {
        gacm.declineAll()
        tcModel.unsetAll()
      },

      getStoredTCString() {
        return Termly.tcf?.getTCString()
      },
    }
  }, [gacm, gvl, lang, tcfSettings])

  return (
    <TCFContext.Provider
      value={ value }
    >
      { children }
    </TCFContext.Provider>
  )
}

TCFProvider.propTypes = {
  children: PropTypes.node,
  lang: PropTypes.string,
  tcfSettings: PropTypes.shape({
    enable_advertiser_consent_mode: PropTypes.bool,
  }),
}
